import { FormikProvider, useFormik } from "formik";
import { useCart, useShop, useUser } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clear } from "store/cart/actions";
import { API_ENDPOINTS, request } from "utilities";
import notification from "utilities/notification";
import { loadScript, toJson } from "utilities/str";
import DefaultAddressQuery from "./DefaultAddressQuery";

const OrderForm = () => {
  const navigate = useNavigate();
  const { items, subTotal, total, totalQuantity, discount, coupon } = useCart();
  const { isUserLoggedIn, user } = useUser();
  const { info: shop } = useShop();
  const dispatch = useDispatch();
  const { data: defaultAddress } = DefaultAddressQuery();
  const address = toJson(defaultAddress?.address);
  const isDisabled = isUserLoggedIn && totalQuantity > 0;
  const [payMethod, setPayMethod] = useState("razorPay");
  const [saleTax, setSaleTax] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    let calculatedTotal = subTotal - discount;
    if (calculatedTotal < 850) {
      setSaleTax(75);
      setGrandTotal(Math.max(calculatedTotal + 75, 0));
    } else {
      setSaleTax(0);
      setGrandTotal(Math.max(calculatedTotal, 0));
    }
  }, [total, discount, subTotal]);

  const initialValues = {
    products: items.map((item) => ({
      order_quantity: item?.quantity,
      product_id: item?.product_id,
      unit_price: item?.price,
      subtotal: item?.price * item?.quantity,
    })),
    status: 1,
    amount: total,
    sales_tax: saleTax,
    coupon_id: coupon?.id,
    shop_id: shop?.id,
    paid_total: grandTotal,
    total: total,
    discount: discount,
    coupon: coupon,
    customer_contact: user?.contact || "0123456789",
    customer_id: user?.id,
    customer_email: user?.email,
    payment_gateway: payMethod,
    billing_address: address,
    shipping_address: address,
  };

  const onSubmit = async (values) => {
    if (!payMethod) {
      notification("error", "Please select a payment method");
      return;
    }
    if (payMethod === "razorPay") {
      await displayRazorpay(values);
    } else {
      handleOrderSubmission(values);
    }
  };

  const handleOrderSubmission = (values) => {
    request.post(API_ENDPOINTS.ORDER, values).then((response) => {
      if (response?.success) {
        notification("success", response?.message);
        dispatch(clear());
        navigate(`/`);
      } else {
        notification("error", response?.message);
      }
    });
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
  });
  async function displayRazorpay(order) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      notification("error", "Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      currency: process.env.REACT_APP_RAZORPAY_CURRENCY,
      amount: grandTotal * 100,
      name: "BEAUTE INDIA",
      description: "LUX" + Date.now(),
      handler: function (response) {
        if (response?.razorpay_payment_id) {
          const paymentData = {
            paymentId: response?.razorpay_payment_id,
            amount: grandTotal * 100,
            currency: "INR",
          };
          request
            .post(API_ENDPOINTS.PAYMENT_VERIFY, paymentData)
            .then((presponse) => {
              const paymentResponse = JSON.parse(presponse.data);
              if (
                paymentResponse?.status === "captured" &&
                paymentResponse?.captured === true
              ) {
                request
                  .post(API_ENDPOINTS.ORDER, order)
                  .then((orderResponse) => {
                    if (orderResponse?.success) {
                      notification("success", orderResponse?.message);
                      dispatch(clear());
                      navigate(`/`);
                    } else {
                      notification("error", orderResponse?.message);
                    }
                  });
              } else {
                notification("error", "Payment Failed. Try Again!!!");
              }
            });
        } else {
          notification("error", "Payment Failed. Try Again!!!");
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <Fragment>
      <FormikProvider value={formik}>
        <div className="payment-option-section">
          <div className="row pt-5 pb-5">
            <div className="col-lg-8">
              <h2 className="payment">Payment Options</h2>
              <div className="choose-payment-mode mt-5 pe-5">
                <ul>
                  <div className="radio-btn-section pe-3">
                    <li>Razor pay </li>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="payment_gateway"
                        id="razorPayOption"
                        value="razorPay"
                        checked={formik.values.payment_gateway === "razorPay"}
                        onChange={(e) => {
                          setPayMethod(e.target.value);
                          formik.setFieldValue(
                            "payment_gateway",
                            e.target.value
                          );
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="razorPayOption"
                      >
                        RazorPay
                      </label>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="total-section">
                <h1 style={{ fontSize: "26px" }} className="mb-5">
                  {" "}
                  <u> Total</u>
                </h1>
                <div className="total-products">
                  {items.map((item, idx) => (
                    <div className="product-price" key={idx}>
                      <h5 style={{ fontSize: "18px" }}>{item?.model?.name}</h5>
                      <span style={{ fontSize: "18px" }}>
                        ₹ {item?.price * item?.quantity}
                      </span>
                    </div>
                  ))}
                  <div className="product-price">
                    <h5>Discount</h5>
                    <span>₹{discount}</span>
                  </div>
                  <div className="mb-2 mt-4">
                    <div className="new2"></div>
                  </div>
                  <div className="product-price">
                    <h5 style={{ fontSize: "20px !important" }}>Sub Total</h5>
                    <span style={{ fontSize: "20px" }}>₹ {grandTotal}</span>
                  </div>
                  <p>Shipping Charges ₹ {saleTax}</p>
                </div>
                <form className="form-submit" onSubmit={formik.handleSubmit}>
                  <button
                    type="submit"
                    className="proceed-checkout-btn payment-btn mt-5"
                    disabled={!isDisabled}
                  >
                    Proceed to Payment
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </FormikProvider>
    </Fragment>
  );
};

export default OrderForm;
